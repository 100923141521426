import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "subtable_template sales-order text-start" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "dataTables_wrapper dt-bootstrap4 no-footer max-sm-text-nowrap" }
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = {
  class: "table align-middle table-row-bordered table-hover fs-6 dataTable no-footer",
  role: "grid"
}
const _hoisted_6 = { class: "fw-bold text-gray-800" }
const _hoisted_7 = { colspan: "2" }
const _hoisted_8 = { colspan: "2" }
const _hoisted_9 = { class: "fs-7 text-line-clamp-1 text-gray-400" }
const _hoisted_10 = { class: "fs-7 text-line-clamp-1 text-gray-400" }
const _hoisted_11 = { class: "flex-1" }
const _hoisted_12 = { class: "fs-7 text-line-clamp-1 text-gray-400" }
const _hoisted_13 = { class: "fs-7 text-line-clamp-1 text-gray-400" }
const _hoisted_14 = { class: "fs-7 text-line-clamp-1 text-gray-400" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["colspan"]
const _hoisted_17 = { class: "d-flex flex-column justify-content-center align-items-center h-60px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_info = _resolveComponent("product-info")!
  const _component_sku_info = _resolveComponent("sku-info")!
  const _component_SkuStatus = _resolveComponent("SkuStatus")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", { colspan: _ctx.tableColspans }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _createElementVNode("tbody", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.items, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(item.position), 1),
                  _createElementVNode("td", _hoisted_7, [
                    _createVNode(_component_product_info, {
                      gallery: _ctx.getAllAallery(item),
                      source_link: '',
                      name: item.name,
                      name_en: item.name,
                      brand_id: item.brand
                    }, null, 8, ["gallery", "name", "name_en", "brand_id"])
                  ]),
                  _createElementVNode("td", _hoisted_8, [
                    _createVNode(_component_sku_info, {
                      brand_article_no: item.brand_article_no,
                      color: item.color,
                      size: item.size,
                      product_identifier_id: '0'
                    }, null, 8, ["brand_article_no", "color", "size"])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("draftOrder.hsCode")), 1),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(item.hs_code || "--"), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("order.ean")), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(item.ean_code || "--"), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("order.countryOfOrigin")), 1),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(item.origin_country || "--"), 1)
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("order.qty")), 1),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(item.qty), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("order.grandTotalBase")), 1),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$currency.currency(
                        item.currency_sales_price,
                        2,
                        ",",
                        ".",
                        "€ "
                      )), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("common.status")), 1),
                    _createVNode(_component_SkuStatus, {
                      class: "fs-7 text-line-clamp-1",
                      status: item.check_result,
                      "status-label": item.__show.check_result
                    }, null, 8, ["status", "status-label"])
                  ])
                ]))
              }), 128)),
              (_ctx.formData.items.length == 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [
                    _createElementVNode("td", { colspan: _ctx.tableColspans }, [
                      _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.isRequest ? "No data found" : ""), 1)
                    ], 8, _hoisted_16)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ], 8, _hoisted_2)
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}