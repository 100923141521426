import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title max-sm-w-100" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1 max-sm-w-100" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "card-toolbar max-sm-w-100"
}
const _hoisted_8 = {
  class: "d-flex flex-wrap justify-content-end gap-3 max-sm-w-100",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-primary max-sm-flex-1",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_add_sale_order"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = {
  type: "button",
  class: "btn btn-light-primary max-sm-text-nowrap max-sm-flex-1",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modal_draft_order_filter_drown"
}
const _hoisted_12 = { class: "svg-icon svg-icon-2" }
const _hoisted_13 = {
  type: "button",
  class: "btn btn-light-primary max-sm-text-nowrap max-sm-flex-1",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_14 = { class: "svg-icon svg-icon-2" }
const _hoisted_15 = { class: "card-body pt-0 vip-table" }
const _hoisted_16 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_window_resize = _resolveComponent("window-resize")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_IndexSubTable = _resolveComponent("IndexSubTable")!
  const _component_PackageStatusTag = _resolveComponent("PackageStatusTag")!
  const _component_SkuStatus = _resolveComponent("SkuStatus")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _component_AddModal = _resolveComponent("AddModal")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14 max-sm-w-100",
              placeholder: _ctx.t('common.search')
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.search]
            ]),
            _withDirectives(_createElementVNode("span", {
              class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
              style: {"cursor":"pointer"},
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
            }, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ], 512), [
              [_vShow, _ctx.search != '']
            ])
          ])
        ]),
        (0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("common.add")), 1)
                ])), [
                  [_directive_auth, { auth: ['add'] }]
                ]),
                _createVNode(_component_window_resize, null, {
                  sm: _withCtx(() => [
                    _createElementVNode("button", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("common.filter")), 1)
                    ])
                  ]),
                  lg: _withCtx(() => [
                    _createElementVNode("button", _hoisted_13, [
                      _createElementVNode("span", _hoisted_14, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("common.filter")), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_FilterDropdown, {
                  type: 1,
                  onSubmitFilter: _ctx.handleFilter,
                  onResetFilter: _ctx.handleFilterReset
                }, null, 8, ["onSubmitFilter", "onResetFilter"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_MBDatatablePlus, {
          class: "max-sm-text-nowrap",
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          "rows-per-page": _ctx.pageSize,
          emptyTableText: _ctx.$t('draftOrder.noDataFound'),
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-sub": _withCtx(({ row: item }) => [
            (_openBlock(), _createBlock(_component_IndexSubTable, {
              key: item.id,
              itemData: item,
              tableColspan: _ctx.tableHeader.length
            }, null, 8, ["itemData", "tableColspan"]))
          ]),
          "cell-order_number": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.order_number), 1)
          ]),
          "cell-package_barcode": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.package_barcode), 1)
          ]),
          "cell-customer": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.user_account_name), 1)
          ]),
          "cell-ordered_qty": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.ordered_qty), 1)
          ]),
          "cell-created_at": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(item.created_at)), 1)
          ]),
          "cell-package_status": _withCtx(({ row: item }) => [
            _createVNode(_component_PackageStatusTag, {
              status: item.state,
              "status-label": item.__show.state
            }, null, 8, ["status", "status-label"])
          ]),
          "cell-sku_status": _withCtx(({ row: item }) => [
            _createVNode(_component_SkuStatus, {
              status: item.sku_check_result,
              "status-label": item.__show.sku_check_result
            }, null, 8, ["status", "status-label"])
          ]),
          "cell-actions": _withCtx(({ row: merchant }) => [
            _createVNode(_component_router_link, {
              to: _ctx.getDraftOrderRouteUrl(_ctx.$route) + merchant.id + '/overview',
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
              onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_16, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "emptyTableText", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ]),
    _createVNode(_component_AddModal, {
      type: 1,
      onUpdateList: _ctx.updateList
    }, null, 8, ["onUpdateList"])
  ], 64))
}