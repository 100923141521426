
import { PackageStatus } from "@/core/directive/type/draftOrder";
import { defineComponent } from "vue";

export default defineComponent({
  name: "package-status-tag",
  props: {
    status: {
      type: Number,
      required: true,
    },
    statusLabel: {
      type: String,
      required: true,
    },
  },
  setup() {
    const getDoubleSaleStatus = (status: number) => {
      const result = new Map([
        [PackageStatus.InProgress, "text-purple bg-purple bg-opacity-10"],
        [PackageStatus.Submitted, "text-pink bg-pink bg-opacity-10"],
        [PackageStatus.Packed, "text-blue bg-blue bg-opacity-10"],
        [PackageStatus.WaitingPickUp, "text-cyan bg-cyan bg-opacity-10"],
        [PackageStatus.PickUp, "text-orange bg-orange bg-opacity-10"],
        [PackageStatus.TransitWarehouse, "text-orange bg-orange bg-opacity-10"],
        [PackageStatus.Checking, "text-orange bg-orange bg-opacity-10"],
        [PackageStatus.PassedByCustoms, "text-orange bg-orange bg-opacity-10"],
        [PackageStatus.Completed, "text-green bg-green bg-opacity-10"],
      ]);
      return result.get(status) || "";
    };
    return {
      getDoubleSaleStatus,
    };
  },
});
