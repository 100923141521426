export enum PackageStatus {
  InProgress = 10,
  Submitted = 20,
  Packed = 30,
  WaitingPickUp = 40,
  PickUp = 50,
  TransitWarehouse = 60,
  Checking = 70,
  PassedByCustoms = 80,
  Completed = 90,
}
