
import { ApiDraftOrders } from "@/core/api";
import { formatDate } from "@/core/directive/function/common";
import { DoubleSaleDraftOrderListItem } from "@/core/directive/interface/draftOrder";
import { ProductItemImage } from "@/core/directive/interface/order";
import { onMounted } from "vue";
import { computed, defineComponent, ref } from "vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import SkuStatus from "./SkuStatus.vue";

export default defineComponent({
  name: "double-sale-order-draft-orders-sub-table",
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    tableColspan: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ["update-list", "reset-form"],
  components: { ProductInfo, SkuInfo, SkuStatus },
  setup(props) {
    const loading = ref<boolean>(false);
    const base = ref("");
    const order = ref("");
    const isRequest = ref<boolean>(false);

    const formData = ref({
      items: [] as DoubleSaleDraftOrderListItem[],
    });

    const options = ref({});

    const tableColspans = computed(() => {
      return props.tableColspan + 2;
    });

    const getAllAallery = computed(() => {
      return (item: DoubleSaleDraftOrderListItem) => {
        let arr: ProductItemImage[] = [];
        item.images.map((li) => {
          arr.push({
            id: 0,
            origin_url: li,
          });
        });
        return arr;
      };
    });

    const getShowInfo = async () => {
      loading.value = true;
      const { data } = await ApiDraftOrders.getDoubleSaleDraftOrderItem({
        order_id: props.itemData.id,
      });
      loading.value = false;
      isRequest.value = true;
      if (data.code === 0) {
        formData.value.items = data.data;
      }
    };

    onMounted(() => {
      getShowInfo();
    });

    return {
      formatDate,
      props,
      loading,
      base,
      order,
      options,
      isRequest,
      formData,
      tableColspans,
      getAllAallery,
    };
  },
});
