
import { defineComponent } from "vue";

export default defineComponent({
  name: "sku-status",
  props: {
    status: {
      type: Number,
      required: true,
    },
    statusLabel: {
      type: String,
      required: true,
    },
  },
  setup() {
    const getSkuStatus = (status: number) => {
      const result = new Map([
        [0, "text-gray-400"],
        [1, "text-success"],
        [2, "text-danger"],
      ]);
      return result.get(status) || "";
    };
    return {
      getSkuStatus,
    };
  },
});
